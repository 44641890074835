import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Indoor_Cameras/IN-6012_HD/NavButtons';
import CompareIndoor from 'components/Products/Compare/CompareIndoorFlyout';
import TableFeatures from 'components/Indoor_Cameras/IN-6012_HD/TableFeatures';
import IndoorData from 'components/Products/Flyouts/Graphs/IndoorGraphsFlyout';
import RadarGraphCard from 'components/Products/RadarGraphs/in6012-radargraph-grid';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-6012 HD",
  "path": "/Indoor_Cameras/IN-6012_HD/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-6012 HD Indoor IP Camera",
  "image": "../P_SearchThumb_IN-6012HD.png",
  "social": "/images/Search/P_SearchThumb_IN-6012HD.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-6012HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IN-6012 HD' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-6012 HD Indoor IP Camera' image='/images/Search/P_SearchThumb_IN-6012HD.png' twitter='/images/Search/P_SearchThumb_IN-6012HD.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-6012_HD/' locationFR='/fr/Indoor_Cameras/IN-6012_HD/' crumbLabel="IN-6012" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-6012-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-6012-hd",
        "aria-label": "in 6012 hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-6012 HD`}</h1>
    <CompareIndoor mdxType="CompareIndoor" />
    <h2 {...{
      "id": "product-overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#product-overview",
        "aria-label": "product overview permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product Overview`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7b9860aa7c447fc9e6373e31caed5a12/573d3/IN-6012_Overview_width1650.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACFUlEQVQoz3WS/UtTURjH948F/ZJQEUIpLLXZ1G3qZm7LNG3o2lKnvbBBjFhuZYquNzFs5JwzsSBfUCpjBSKtLK2mKwPXvLt3n7h3TUHtC+fwnMOXz3lejiojish6F3tP38AD+vsHGRoeIZn8qdxns1llif98cixLEATin77wMb7CRvIXeakEUSQtiPjvDdJsc2A9f5Eao4XA3T7FIEnSgcDhJyFabE6amu3YHV1Mz8zngPL2dXUVV7ebSzYnZ7V6ThQWUVFVo2SRh8rAPGxrK0Wl3sip4tOc0Wg5dPgINSbrLjD+eQVnexcTky9we7w8HhpWzn/S6QOBa2vfuHzFhd3RzskiNR2uq/h6ehElKQeUde26m1DoGbNz84xFooxHJw/soaztbYHb/l5eTc8yMztHLPaBmbkFxacAl5aWsVgbqTdfoEpnpKi4lP7BRzuAvRmur29ga3VSa7KiraxGo9UzMjqeK3lz8zeGahMlpeW02dtpaGqjvMJAnaWRUHhiB5jJZJQ4lUphd3RiNJ3DbGlQelmmqaSu1sJYdArV9x8JZRA6fS0Opwt1iYaCgqMcO15IR7d7p+w8MJFIojPU09baQpWuAnWZFnWpFpPdTOcNDyr59TdvF4lEooTDEYJB+S8OELz/kIXXi/syFESJp6FRvD19BHoHuOXzc9Prw3MnwPOpl7tD+Z/2DkWScn0U9xlzH/sv0EuVDRuVDAAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7b9860aa7c447fc9e6373e31caed5a12/e4706/IN-6012_Overview_width1650.avif 230w", "/en/static/7b9860aa7c447fc9e6373e31caed5a12/d1af7/IN-6012_Overview_width1650.avif 460w", "/en/static/7b9860aa7c447fc9e6373e31caed5a12/7f308/IN-6012_Overview_width1650.avif 920w", "/en/static/7b9860aa7c447fc9e6373e31caed5a12/e1c99/IN-6012_Overview_width1650.avif 1380w", "/en/static/7b9860aa7c447fc9e6373e31caed5a12/e02f7/IN-6012_Overview_width1650.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7b9860aa7c447fc9e6373e31caed5a12/a0b58/IN-6012_Overview_width1650.webp 230w", "/en/static/7b9860aa7c447fc9e6373e31caed5a12/bc10c/IN-6012_Overview_width1650.webp 460w", "/en/static/7b9860aa7c447fc9e6373e31caed5a12/966d8/IN-6012_Overview_width1650.webp 920w", "/en/static/7b9860aa7c447fc9e6373e31caed5a12/445df/IN-6012_Overview_width1650.webp 1380w", "/en/static/7b9860aa7c447fc9e6373e31caed5a12/44758/IN-6012_Overview_width1650.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7b9860aa7c447fc9e6373e31caed5a12/81c8e/IN-6012_Overview_width1650.png 230w", "/en/static/7b9860aa7c447fc9e6373e31caed5a12/08a84/IN-6012_Overview_width1650.png 460w", "/en/static/7b9860aa7c447fc9e6373e31caed5a12/c0255/IN-6012_Overview_width1650.png 920w", "/en/static/7b9860aa7c447fc9e6373e31caed5a12/b1001/IN-6012_Overview_width1650.png 1380w", "/en/static/7b9860aa7c447fc9e6373e31caed5a12/573d3/IN-6012_Overview_width1650.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7b9860aa7c447fc9e6373e31caed5a12/c0255/IN-6012_Overview_width1650.png",
              "alt": "INSTAR IN-6012 HD",
              "title": "INSTAR IN-6012 HD",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <TableFeatures mdxType="TableFeatures" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-6012 HD is an all-round solution for indoor surveillance. The camera head is remotely controllable and equipped with infrared diodes to utilize night vision in pitch dark environments. The integrated IR cut filter on the other hand allows for the capturing of native colours, as seen by the human eye. Through the use of an efficient h.264 compression codec it is able of recording video files on a SD memory card with comparably small size but best picture results. Any device (i.e. smartphones) can connect to the cameras web interface and allows you to control the pan & tilt feature. With 350 degrees horizontally and 100 degrees vertically range of movement. Thanks to its compact size and its modern design, you won't be just limited to use it as a surveillance camera but also for your personal projects. The FTP support allows you to use the IN-6012 HD as a multi-functional webcam for integration on your website.`}</p>
    <IndoorData mdxType="IndoorData" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "hardware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hardware",
        "aria-label": "hardware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hardware`}</h3>
    <ul>
      <li parentName="ul">{`Wide Dynamic Range (WDR) 720p `}<Link to="/Indoor_Cameras/IN-6012_HD/Technical_Specifications/" mdxType="Link">{`Omnivision CMOS Sensor`}</Link></li>
      <li parentName="ul">{`Remote controlled Pan & Tilt - 350° pan and 100° tilt`}</li>
      <li parentName="ul">{`2-Way audio via an integrated `}<Link to="/Web_User_Interface/1080p_Series/Multimedia/Audio/" mdxType="Link">{`Microphone & Speaker`}</Link></li>
      <li parentName="ul">{`Alarm I/O for external sensors (e.g. `}<Link to="/Products/IN-Motion/" mdxType="Link">{`Motion`}</Link>{` or smoke detectors) and receivers (e.g. house automations)`}</li>
    </ul>
    <h3 {...{
      "id": "network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#network",
        "aria-label": "network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network`}</h3>
    <ul>
      <li parentName="ul">{`RJ-45 Ethernet port for an easy integration into your 100Mbit home network`}</li>
      <li parentName="ul">{`Compatible with 2.4 GHz `}<Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/" mdxType="Link">{`2.4 GHz WiFi`}</Link>{` IEEE 802.11 b/g/n up to 54Mbit networks with WEP, WPA and WPA2 security`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/Network/IP_Config/" mdxType="Link">DHCP</Link> and <Link to="/Web_User_Interface/720p_Series/Network/UPnP/" mdxType="Link">UPnP</Link> support for an automatic network integration
      </li>
      <li parentName="ul">{`Integrated personal, free DDNS ("Internet") Address and P2P User ID to access your camera through the Internet`}</li>
    </ul>
    <h3 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h3>
    <ul>
      <li parentName="ul">{`Free `}<Link to="/Software/Android/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/" mdxType="Link">{`iPhone`}</Link>{` and `}<Link to="/Software/Windows/" mdxType="Link">{`Windows Phone`}</Link>{` App`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/" mdxType="Link">Web user interface</Link> allows an easy configuration and camera control through your default web browser (Chrome, Safari, Firefox, Internet Explorer)
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/System/User/" mdxType="Link">Multi user management</Link> and simultaneous login for 3 users
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/Alarm/Areas/" mdxType="Link">Software-based motion</Link> and <Link to="/Web_User_Interface/720p_Series/Alarm/Actions/" mdxType="Link">audio</Link> detection and <Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link"> email notification</Link> with attached snapshots
      </li>
      <li parentName="ul">{`Record videos directly to an `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`Internal SD Card`}</Link></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <RadarGraphCard mdxType="RadarGraphCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The 1/3 inch WDR sensor is built on a 4.3 micron OmniPixel3-HS™ Pixel enabling 720p HD at unparalleled low-light sensitivity and a dynamic range in colour of 115 dB – compared to the 60 db of a regular VGA IP Camera. WDR allows you to capture high detailed colour videos even in high-contrast environmental lighting conditions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      